<template>
  <div class="messages-search">
    <div class="messages-search-content">
      <div class="messages-search-input">
        <iconic :name="`${loading ? 'gspinner' : 'search'}`" />
        <input type="text" name="search" placeholder="Buscar contacto" autocomplete="off" @input="search" :value="q" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["loading", "q"],
  methods: {
    search: function({ target: { value } }) {
      this.$emit("search", { value });
    },
  },
};
</script>

<style lang="scss" scoped>
.messages-search {
  position: relative;
  &-content {
    padding: $mpadding;
  }
  &-input {
    position: relative;
    display: flex;
    align-items: center;
    .iconic {
      position: absolute;
      left: $mpadding;
      font-size: 120%;
    }
  }
  input[name="search"] {
    padding: $mpadding $mpadding $mpadding $mpadding * 3;
    border-radius: $mpadding * 2;
    background-color: #f4f4f4;
    width: 100%;
    border: none;
  }
}
</style>
